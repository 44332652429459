.toast-success-container,.toast-error-container,.toast-info-container {
    color: #000 !important;
    border-radius: 8px !important;
    background: #FFFFFF !important;
    box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
  }
  
  .toast-success-container {
    border: 1px solid #34A853 !important;
  }
  .toast-error-container {
    border: 1px solid #e62451 !important;
  }
  .toast-info-container {
    border: 1px solid #5c2e91 !important;
  }
  .toast-success-container-after, .toast-error-container-after,.toast-info-container-after {
    overflow: hidden;
    position: relative;
  }
  
  .toast-success-container-after::after,.toast-error-container-after::after,.toast-info-container-after::after {
    top: 0;
    left: 0;
    content: '';
    width: 7px;
    height: 100%;
    position: absolute;
    display: inline-block;
  }
  .toast-success-container-after::after{
    background-color: #34A853;
  }
  .toast-error-container-after::after{
    background-color: #e62451;
  }
  .toast-info-container-after::after{
    background-color: #5c2e91;
  }
  
  .progress-success{
      background: #34A853;
  }
  .progress-error{
    background: #e62451;
}
.progress-info{
    background: #5c2e91;
}