.login-main {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	margin: auto;
	padding: 0px;
	width: 60%;
	max-width: 450px;
}
.login-main h6 {
	font-size: 18px;
	opacity: 0.5;
	margin: 26px 0px 30px;
}

.login-main form {
	margin-top: 30px;
}
.login-main .form-group {
	margin-bottom: 10px;
    // position: relative;
}
.login-logo {
	margin-top: -40px;
}


.login-main .btn {
	background-color: #43425D;
	font-size: 13px;
	color: #fefefe;
	/* padding:10px 15px; */
	width: 100%;
	margin: 0px 0px 10px;
}
.welcome {
	background: #ded5e9;
	padding: 25px;
	color: #5C2E91;
}

.welcome h5 {
	font-size: 16px;
	color: #5C2E91;
}

.welcome p {
	font-size: 13px;
	color: #5C2E91;
	margin-bottom: 20px;
}

//
/* Chekcbox */
.custom-checkbox {
	float: left;
}
.custom-control-label {
	padding: 3px 0px 0px 5px;
	font-size: 12px;
}

.custom-control-label::before {
	width: 16px;
	height: 16px;
}

.custom-control-label::after {
	width: 16px;
	height: 16px;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
	background-color: transparent;
	border-color: #adb5bd;
}

.custom-control-input:focus~.custom-control-label::before {
	box-shadow: none;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
	border-color: #adb5bd;
}

.custom-control-input:checked~.custom-control-label::before {
	color: #fff;
	border-color: #5C2E91;
	background-color: #5C2E91;
}

/* checkbox end */

.text-danger {
    color: #dc3545!important;
}

//
.forgot a {
	margin: 25px auto 0px;
	color: #74788D;
	font-size: 13px;
    display: table;
    text-decoration: none;
}

.forgot .bx:before {
	content: "\edbf";
	color: #74788D;
}

.login-main label {
    font-size: 12px;
    margin-bottom: .5rem;
}

.login-btn1 {
	background-color: #5C2E91 !important;
	font-size: 13px;
	color: #fff;
	/* height: 36px; */
	padding: 5px 12px;
	font-weight: 400;
	border: 1px solid #5C2E91 !important;
	border-radius: 3px;
}

.form-check{
    padding-left: 0!important;
}

.login-main input::placeholder {
    color: #2d2d2d;
    font-size: 12px;
}

.login-main input:-ms-input-placeholder {
    color: #2d2d2d;
    font-size: 12px;
}

.login-main input::-ms-input-placeholder {
    color: #2d2d2d;
    font-size: 12px;
}
.login-main textarea::placeholder {
    color: #2d2d2d;
    font-size: 12px;
}

.login-main textarea:-ms-textarea-placeholder {
    color: #2d2d2d;
    font-size: 12px;
}

.login-main textarea::-ms-textarea-placeholder {
    color: #2d2d2d;
    font-size: 12px;
}
.form-control:focus {
	box-shadow: none;
	border-color: #dedede;
}
small {
    font-size: 80%;
    font-weight: 400;
}
.card-body{
    padding: 0 1rem 0.5rem 1rem!important;
}

.thank{
    // border: 1px solid red;
    text-align: center;
    margin: 100px 0;
}
.thank > h5{
    margin: 30px 0!important;
}
.thank > h6{
    margin: 75px 0!important;
    color: #000!important;
    opacity: 1!important;
}
.thank-button{
    font-size: 13px;
    background-color: #5c2e91;
    margin-bottom: 15px;
    padding: 5px 10px!important;
    border-radius: 3px;
    width: auto;
    color: #fff;
}

@media(max-width: 768px){
    .login-main {
		width: 90%;
		position: relative;
		transform: unset;
		left: unset;
		top: unset;
		margin: 25px auto;
    }
    
    .thank{
        margin: 80px 0;
    }
    .thank > h5{
        font-size: 18px;
        margin: 30px 0!important;
    }
    .thank > h6{
        margin: 75px 0!important;
    }
}